import React from "react"
import { graphql } from "gatsby"
import { Row, Col, Button } from "antd"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Summary from "../components/summary"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout
      location={location}
      title={siteTitle}
      work={post.frontmatter.tags[0]}
      name={post.frontmatter.title}
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.image}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className="info">
          <div className="cover">
            <div
              style={{
                backgroundImage: `url(${post.frontmatter.image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
                paddingTop: "56.25%",
              }}
            />
          </div>
          <header className="summary">
            <div className="description">
              <div>
                <h1 itemProp="headline">{post.frontmatter.title}</h1>
                <span>{post.frontmatter.description}</span>
              </div>
              {post.frontmatter.url && (
                <OutboundLink href={post.frontmatter.url} target="_blank">
                  <Button size="large" style={{ width: "100%" }}>
                    바로가기
                  </Button>
                </OutboundLink>
              )}
            </div>
          </header>
        </div>

        {post.html && (
          <section
            className="article-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        )}
        {posts && posts.length > 0 && (
          <>
            <p className="works">CONTENT LIST</p>
            <Row gutter={[24, 24]}>
              {posts.map(post => {
                return (
                  <Col
                    key={post.fields.slug}
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={24}
                  >
                    <Summary post={post} />
                  </Col>
                )
              })}
            </Row>
          </>
        )}
        <footer></footer>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String]!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        image
        tags
        name
        description
        url
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { tags: { in: $tag } } }
      sort: { fields: [frontmatter___order], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          description
          image
          status
          tags
          url
        }
      }
    }
  }
`
